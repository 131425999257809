.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*Standard*/
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body {
  background-color:#FFFFFF;
  color: #505050;
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.8; }

/* Headings */
h1, h2, h3, h4, h5, h6 {
  line-height: 1;
  font-weight: 300; }

a {
  text-decoration: none;
  color: #3cb5f9; }

a:hover {
  color: #0793e2; }

#wrapper {
  width: 100%;
  margin: 0 auto; }

#main {
  background-color: #fff;
  padding-top: 150px; }

.searchbar {
	text-align: center;
	vertical-align: middle;
}

.container {
  width: 90%;
  margin: 0 auto;
  padding: 0 0px; }

.userProfileArea {
	display: block;
	position: absolute;
	top: 0px;
	left: 0px;
}

.floatRight {
	float: right;
}

section {
  padding: 60px 0; }
  section h1 {
    font-weight: 700;
    margin-bottom: 10px; }
  section p {
    margin-bottom: 30px; }
    section p:last-child {
      margin-bottom: 0; }
  section.color {
    background-color: #3cb5f9;
    color: white; }

/*Header*/
header {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #0683c9;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
  transition: height 0.3s; }
  header h1#logo {
    display: inline-block;
    height: 150px;
    line-height: 150px;
    float: left;
    font-family: "Oswald", sans-serif;
    font-size: 60px;
    color: white;
    font-weight: 400;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
  header nav {
    display: inline-block;
    float: right; }
    header nav a {
      line-height: 200px;
      margin-left: 20px;
      color: #9fdbfc;
      font-weight: 700;
      font-size: 18px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
      header nav a:hover {
        color: white; }
  header.smaller {
    height: 75px; }
    header.smaller h1#logo {
      width: 150px;
      height: 75px;
      line-height: 75px;
      font-size: 30px; }
    header.smaller nav a {
      line-height: 75px; }

/* Footer */
/* Extras */
.clearfix:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0; }
  footer {font-size:70%;
         text-align: center;
         padding: 10px;
         background-color: #869dc7;
		 clear: both;
}

/* Media Queries */
@media all and (max-width: 660px) {
  header h1#logo {
    display: block;
    float: none;
    margin: 0 auto;
    height: 10px;
    line-height: 100px;
    text-align: center; }
  header nav {
    display: block;
    float: none;
    height: 50px;
    text-align: center;
    margin: 0 auto; }
    header nav a {
      line-height: 50px;
      margin: 0 10px; }
  header.smaller {
    height: 75px; }
    header.smaller h1#logo {
      height: 40px;
      line-height: 40px;
      font-size: 30px; }
    header.smaller nav {
      height: 35px; }
      header.smaller nav a {
        line-height: 35px; } 
          footer {font-size:70%;
         text-align: center;
         padding: 10px;
         background-color: #869dc7;
		 clear: both;
}

}


 {box-sizing: border-box;}

/* Button used to open the contact form - fixed at the bottom of the page */
.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

/* The popup form - hidden by default */
.form-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width input fields */
.form-container input[type=text], .form-container input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
}

/* When the inputs get focus, do something */
.form-container input[type=text]:focus, .form-container input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #0683c9;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: #fc9220;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
} 
